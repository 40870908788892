import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Section from '../../molecules/Section/Section';
import { breakpoints } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import Image from '../../utils/OptimizedImage';
import {
  Content,
  DesktopImageWrapper,
  Heading,
  Kicker,
  MobileImageWrapper,
  Subhead,
  Wrapper,
} from './BannerAd.styles';

const bgColorMap = {
  Light: {
    bg: colors.base.heroSurface,
    heading: colors.gray[900],
    kicker: colors.primary[500],
    copy: colors.gray[600],
    copyAccent: colors.gray[600],
  },
  Blue: {
    bg: colors.primary[900],
    heading: colors.base.white,
    kicker: colors.base.white,
    copy: colors.gray[100],
    copyAccent: colors.base.white,
  },
  Charcoal: {
    bg: colors.gray[900],
    heading: colors.base.white,
    kicker: colors.base.white,
    copy: colors.gray[300],
    copyAccent: colors.base.white,
  },
  Cera: {
    bg: colors.base.heroSurface,
    heading: colors.gray[900],
    kicker: colors.gray[500],
    copy: colors.gray[600],
    copyAccent: colors.gray[600],
  },
  Green: {
    bg: colors.green[800],
    heading: colors.base.white,
    kicker: colors.base.white,
    copy: colors.gray[100],
    copyAccent: colors.base.white,
  },
};

const BannerAd = ({
  component: {
    background,
    backgroundImageDesktop,
    backgroundImageMobile,
    callToAction,
    heading,
    headingKicker,
    subhead,
  },
}) => {
  const colorMap = bgColorMap[background];
  const largeScreen = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  return (
    <Wrapper bg={colorMap.bg}>
      <Section bgColor={colorMap.bg}>
        <Content>
          <Kicker color={colorMap.kicker}>{headingKicker}</Kicker>
          <Heading color={colorMap.heading}>{heading}</Heading>
          <Subhead color={colorMap.copy}>{subhead}</Subhead>
          {callToAction && (
            <CallToAction
              icon={callToAction.icon}
              link={callToAction.url}
              reverse={callToAction.reverse}
              size={callToAction.size}
              value={callToAction.label}
              variant={callToAction.variant}
            />
          )}
        </Content>
        {largeScreen && backgroundImageDesktop && (
          <DesktopImageWrapper>
            <Image
              alt={backgroundImageDesktop.alt}
              image={backgroundImageDesktop.gatsbyImageData}
              title={backgroundImageDesktop.title}
            />
          </DesktopImageWrapper>
        )}
      </Section>
      {!largeScreen && backgroundImageMobile && (
        <MobileImageWrapper>
          <Image
            alt={backgroundImageMobile.alt}
            image={backgroundImageMobile.gatsbyImageData}
            title={backgroundImageMobile.title}
          />
        </MobileImageWrapper>
      )}
    </Wrapper>
  );
};

export default BannerAd;
