import styled from 'styled-components';
import { InnerWrapper } from '../../molecules/Section/Section.styles';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { font } from '../../styles/atoms/typography';

export const Wrapper = styled.div`
  background-color: ${props => props.bg};
  position: relative;
  overflow: hidden;

  ${InnerWrapper} {
    padding-top: 64px;
    padding-bottom: 0;

    ${atMinWidth.md`
      padding-top: 101.5px;
      `}

    ${atMinWidth.lg`
      padding-left: 101.5px;
      padding-right: 101.5px;
      padding-bottom: 101.5px;
    `}

    ${atMinWidth.xl`
      padding: 144.5px 23.5px;
    `}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${atMinWidth.lg`
    width: 50%;
  `}
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${props => props.color};
`;

export const Heading = styled.div`
  ${font('display', 'lg', '700')}
  color: ${props => props.color};

  ${atMinWidth.sm`
    ${font('display', 'xl', '700')}
  `}

  ${atMinWidth.xl`
    ${font('display', 'xxl', '700')}
  `}
`;

export const Subhead = styled.div`
  color: ${props => props.color};
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  ${font('text', 'md', '400')}
  justify-content: inherit;
`;

export const DesktopImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;

  .gatsby-image-wrapper {
    overflow: visible;
    position: static;

    img {
      position: absolute;
      inset: 0;
      height: 100%;
      object-position: left bottom;
      object-fit: cover !important;
    }
  }
`;

export const MobileImageWrapper = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    aspect-ratio: 4/3;

    ${atMinWidth.sm`
      aspect-ratio: 12/7;
    `}

    ${atMinWidth.md`
      aspect-ratio: 2/1;
    `}

    img {
      object-position: right bottom;
      object-fit: contain !important;
    }
  }
`;
